import React, {useEffect, useState} from 'react';
import "../../assets/css/SelectYourProfile.css";
import {Button} from "react-bootstrap";
import Footer from "../../core/layout/footer/footer";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


function BusinessView(props) {
    const [showUI, setShowUI] = useState(false);
    const {isMobileView, isXSView} = props;


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const displayUI = () => {
        setShowUI(true);
        window.scrollTo(0, 0)
    };

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };

    return (
        <div>
            <div className='topsection'/>
            {!showUI &&
            <div className="container-x">
                <div className="bg-business">
                    <div className="head-block-select-para">
                        <div className="page-title text-center my-3">
                            Need financial backup
                            for your business cycles?
                        </div>
                        <div className="para-select-profile mb-4">
                            Create an alternative source of cash flow to hedge against downturns in
                            your
                            business
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button className="secondary-button icon-orange"
                                    onClick={displayUI}>
                                Read More
                            </Button>
                        </div>
                    </div>
                </div>
                {isMobileView ?
                    <div>
                        <img src={s3BaseURL + '/selectYourProfile/businessPrimaryBg.jpg'}
                             className="img-fluid bannerImg "
                             alt=""/>
                    </div>
                    :
                    <div/>
                }
            </div>

            }

            {showUI &&
            <div className="container-x"
            style={{ height: isMobileView ? '' : "calc(100dvh - 140px)"}}>
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="page-title my-3">
                            Diversify and reduce your business risk
                        </div>
                        <div className="content mb-3">
                            Running a business is challenging, to say the least.
                            And putting everything back into your business is
                            tempting, but not advisable for the long run. It’s
                            important to have financial assets as a backup for
                            your business income in case of a downturn.
                        </div>
                        <div className="content mb-3">
                            To accomplish this, we will understand your business, business cycles,
                            and
                            cash flow requirements for meeting your family expenses. We will
                            then design investment models for creating an
                            alternative source of passive income from
                            investments which are tax efficient.
                        </div>
                        <div className="content mb-3">Investments can be done in the name of the
                            business entity
                            and/or the partners/directors.
                            To start, we will target building an investment
                            corpus for you which can cover your business
                            income for 3-5 years.
                        </div>
                        <div className="contact-us-text mb-3">Get in touch with us
                            <a href="/contact-us" className="contact-us">Click Here</a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="d-none d-lg-block">
                            <div className="d-flex justify-content-center">
                                <img src={s3BaseURL + "/selectYourProfile/businessSecondaryBg.jpg"}
                                     className="img-fluid img img-container"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {renderFooter()}
        </div>
    );
}

BusinessView.propTypes = {};

export default BusinessView;
