import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


function WealthCreationView(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    return (
        <div>
            <div className="topsection"/>
            <div className="main-container pt-lg-4 pt-0">
                <div className="container-x">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="page-title mb-3">
                                Wealth Creation
                            </div>
                            <div className="page-sub-title mb-3">
                                The accumulation of assets over
                                a long period of time
                            </div>
                            <div className="img-block px-5 d-lg-none d-block">
                                <img src={s3BaseURL + '/homepage/wealthCreation.jpg'}
                                     className="img-fluid bannerImg mb-3"
                                     alt=""/>
                            </div>
                            <div className="section-header mb-3">
                                The 3 laws of Wealth Creation are:
                            </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-2">
                                                <img src={s3BaseURL + '/logo/earnMoney.jpg'}
                                                     className="img-fluid"
                                                     alt=""/>
                                            </div>
                                            <div className="col-9">
                                                <p className="content">Spend lesser than
                                                    you earn
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-2">
                                                <img src={s3BaseURL + '/logo/longTerm.jpg'}
                                                     className="img-fluid"
                                                     alt=""/>
                                            </div>
                                            <div className="col-9">
                                                <p className="content">Leave it alone for a long
                                                    time, so it
                                                    can grow
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-2">
                                                <img src={s3BaseURL + '/logo/growth.jpg'}
                                                     className="img-fluid"
                                                     alt=""/>
                                            </div>
                                            <div className="col-9">
                                                <p className="content">Invest your income wisely
                                                    (ideally
                                                    20%, minimum 10%)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div className="content mb-3">
                                When you work with Simplicity, we’ll make sure that you develop this habit,
                                along with your next generation. As you get older, your Active Income will
                                gradually decrease and be replaced with Passive Income.
                            </div>
                            <div className="contact-us-text mb-3">Get in touch with us
                                <a href="/contact-us" className="contact-us">Click Here</a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="img-block px-5 d-lg-block d-none">
                                <img src={s3BaseURL + '/homepage/wealthCreation.jpg'}
                                     className="img-fluid bannerImg"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {renderFooter()}
        </div>
    );
}

export default WealthCreationView;
