import React, {Suspense, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import {getMetaTagUi} from "./utils/seoMetaTagHelper";
import {setCurrentPathname, setPrevLocation, getSeoData} from "./core/actions/appActions";
import PrePostLoginRoute from "./core/webRouteWrapper/PrePostLoginRoute";
import HomePage from "./home/HomePage";
import pageNotFound from "./core/components/PageNotFound/pageNotFound";
import DLLoader from "./core/components/Loader/loader";
import Header from "./core/layout/header/header";
import Footer from "./core/layout/footer/footer";
import ToastMessage from "./core/components/toastMessage/toastMessage";
import About from "./about/About";
import RetirementPlanning from "./service/retirementPlanning/RetirementPlanning";
import WealthCreation from "./service/wealthCreation/WealthCreation";
import RiskManagement from "./service/riskManagement/RiskManagement";
import InternationalInvestments from "./service/internationalInvestments/InternationalInvestments";
import GlobalTaxAdvisory from "./service/globalTaxAdvisory/GlobalTaxAdvisory";
import EstatePlanning from "./service/estatePlanning/EstatePlanning";
import TreasuryOperations from "./service/treasuryOperations/TreasuryOperations";
import Event from "./knowledgeBank/events/Event"
import Blogs from "./knowledgeBank/blogs/Blog"
import WhitePapers from "./knowledgeBank/whitepapers/WhitePapers"
import MutualFund from "./products/MutualFunds/MutualFund";
import MyAccount from "./products/MyAccount/MyAccount";
import Stock from "./products/Stock/Stock";
import ContactUs from "./contactUs/ContactUs";
import Business from "./selectYourProfile/business/Business";
import ForHer from "./selectYourProfile/forHer/ForHer";
import Global from "./selectYourProfile/global/Global";
import NewGen from "./selectYourProfile/newGen/NewGen";
import Professional from "./selectYourProfile/professional/Professional";
import Salaried from "./selectYourProfile/salaried/Salaried";
import Retiree from "./selectYourProfile/retiree/Retiree";
import SIP from "./products/SIP/SIP";
import SignUp from "./login/signUp/SignUp";
import SubBlogView from "./knowledgeBank/blogs/subBlogComponent/SubBlogView";
import EventSubView from "./knowledgeBank/events/subEventComponent/EventSubView";
import DLToastMessage from "./core/components/DLToastMessage/DLToastMessage";
import BottomMenuOption from "./core/layout/bottomMenuOptions/BottomMenuOption";
import Process from "./process/Process";

const AppNavigator = (props) => {

    const [seoMetaDataState, setSeoMetaDataState] = useState({
        seoMetaData: null,
        isMetaDataCallFired: false,
    });

    useEffect(() => {
        zoomOutMobile();
    }, []);

    useEffect(() => {
        setCurrentPathname(props.location.pathname);
    }, [props.location.pathname]);

    const {isMobileView} = props;

    const renderContent = () => {
        const {seoMetaData} = seoMetaDataState;

        return (
            <div className="h-100 bg-white" id="">
                {getMetaTagUi(props.location.pathname)}
                <Suspense fallback={(<DLLoader type={"screen"} isVisible={true}/>)}>
                    <Switch>
                        <PrePostLoginRoute exact path="/" component={HomePage}/>
                        <PrePostLoginRoute exact path="/about-us" component={About}/>
                        <PrePostLoginRoute exact path="/services/retirement-investments" component={RetirementPlanning}/>
                        <PrePostLoginRoute exact path="/services/wealth-creation" component={WealthCreation}/>
                        <PrePostLoginRoute exact path="/services/risk-management" component={RiskManagement}/>
                        <PrePostLoginRoute exact path="/services/international-investments" component={InternationalInvestments}/>

                        <PrePostLoginRoute exact path="/services/global-tax-advisory" component={GlobalTaxAdvisory}/>
                        <PrePostLoginRoute exact path="/services/estate-planning" component={EstatePlanning}/>
                        <PrePostLoginRoute exact path="/services/treasury-operations-for-corporates" component={TreasuryOperations}/>

                        <PrePostLoginRoute exact path="/process-chart" component={Process}/>


                        <PrePostLoginRoute exact path="/my-account" component={MyAccount}/>
                        <PrePostLoginRoute exact path="/products/mutual-funds" component={MutualFund}/>
                        <PrePostLoginRoute exact path="/products/stocks" component={Stock}/>
                        <PrePostLoginRoute exact path="/sip" component={SIP}/>


                        <PrePostLoginRoute exact path="/select-your-profile/business" component={Business}/>
                        <PrePostLoginRoute exact path="/select-your-profile/for-her" component={ForHer}/>
                        <PrePostLoginRoute exact path="/select-your-profile/global" component={Global}/>
                        <PrePostLoginRoute exact path="/select-your-profile/new-gen" component={NewGen}/>
                        <PrePostLoginRoute exact path="/select-your-profile/professional" component={Professional}/>
                        <PrePostLoginRoute exact path="/select-your-profile/salaried" component={Salaried}/>
                        <PrePostLoginRoute exact path="/select-your-profile/retiree" component={Retiree}/>



                        <PrePostLoginRoute exact path="/knowledge-bank/events" component={Event}/>
                        <PrePostLoginRoute exact path="/knowledge-bank/articles" component={Blogs}/>
                        <PrePostLoginRoute exact path="/knowledge-bank/publications" component={WhitePapers}/>


                        <PrePostLoginRoute exact path="/knowledge-bank/articles/:id" component={SubBlogView}/>
                        <PrePostLoginRoute exact path="/knowledge-bank/events/:id" component={EventSubView}/>

                        <PrePostLoginRoute exact path="/contact-us" component={ContactUs}/>
                        <PrePostLoginRoute exact path="/sign-up" component={SignUp}/>



                        <Route path="*" component={pageNotFound}/>
                    </Switch>
                </Suspense>
                <ToastMessage/>
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <Header {...props}/>
        );
    };

    const zoomOutMobile=()=>{
        var viewport = document.querySelector('meta[name="viewport"]');

        if (!viewport) {
            return;
        }

        if (window.innerWidth >= 384) {
            return;
        }

        let initScale = window.innerWidth / 384;
        initScale = Math.round(initScale * 100) / 100;

        viewport.content = "initial-scale=" + initScale;
    };

    return (
        <div style={{height:'100dvh'}}>
            {renderHeader()}
            <div className='h-100'>
                {renderContent()}

            </div>
            <DLToastMessage/>
            {
                isMobileView ?  <BottomMenuOption/> :''

            }
        </div>

    );
};


AppNavigator.propTypes = {};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    windowHeight: state.appState.deviceInfo.windowHeight,
    isDrawerOpen: state.appState.isDrawerOpen,
});

export default connect(mapStateToProps, {
    setPrevLocation
})(withRouter(AppNavigator));

