import React, {useEffect, useRef, useState} from 'react';
import Footer from "../../core/layout/footer/footer";
import eventNew from "./EventModule.json";
import {Col} from "react-bootstrap";
import {Pagination} from '@mui/material';
import moment from 'moment';
import {Link, withRouter} from "react-router-dom";
import fileNotFoundIcon from '../../assets/img/KnowledgeBank/fileNotFoundIcon2.jpg';
import {useDispatch, useSelector} from "react-redux";

import {setSearchEvent} from "../../core/reducers/appReducer";

function EventView(props) {
    const {isMobileView, isXSView} = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const data1 = eventNew;

    const inputbox=useRef(null);
    // event.map(e=>console.log(e.url));


    const dispatch=useDispatch();
    const searchQueryStore=useSelector(state => state.appState.searchEventText);

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 6;
    const lastPage = currentPage * recordsPerPage;
    const firstPage = lastPage - recordsPerPage;
    const records = data1.slice(firstPage, lastPage);
    const npages = Math.ceil(data1.length / recordsPerPage);


    const [searchQuery, setSearchQuery] = useState('');
    const [searchElement, setSearchElement] = useState([]);
    const recordsSearchbox = searchElement.slice(firstPage, lastPage);
    const npagesSearchbox = Math.ceil(searchElement.length / recordsPerPage);

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        dispatch(setSearchEvent(query));
        console.log("this is redux",searchQueryStore);


        // Split the search query into individual words
        const queryWords = query.split(' ');
        // Filter the event array based on search query
        const filteredEvents = data1.filter(event => {
            // Convert the event title to lowercase
            const title = event.title.toLowerCase();
            // Check if every word in the search query is present in the event title
            return queryWords.every(qWord => title.includes(qWord));
        });

        // Update the state with filtered results
        setSearchElement(filteredEvents);
        setCurrentPage(1);


    };
    if(searchQueryStore!=='' && searchQuery=='') {
        // setSearchQuery(searchQueryStore);
        handleSearch(event = {
            target: {
                value: searchQueryStore
            }
        });
    }



    //Pagination
    const handlePage = (event, page) => {
        setCurrentPage(page);
    };
    //Scroll to Top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This makes the scroll smooth
        });
    };
    const handleKeyPress = (event) => {
        console.log("this is handlekey",event.key)
        if (event.key === 'Enter') {

            event.preventDefault();
            if(inputbox.current)
            {
                inputbox.current.blur();
            }
        }
    };

    return (

        <div>
            <div className="topsection"/>
            <section className="container-x">
                <div className="pt-md-3 pt-0 knowledge-bank-title-pb page-title">
                    Events
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12"/>
                    <div className="col-lg-6 col-md-12">
                        <input type="text"
                               ref={inputbox}
                               onKeyPress={handleKeyPress}
                               value={searchQuery} onChange={handleSearch}
                               className="search-box m-0"
                               placeholder="Search"/>
                        <i className="fa fa-search" aria-hidden="true"/>
                    </div>
                </div>

                <div className="mb-4">
                    <div className="row ">
                        {searchQuery === '' ? (records.map((e, index) =>
                                <div key={index}
                                     className={isMobileView ? "d-flex flex-column align-items-center justify-content-center col-12" : "col-4 "}
                                     style={{marginBottom: '94px'}}>
                                    <div className={isMobileView  ? "text-center" :'text-start'}
                                         style={{width: isMobileView ? isXSView ? '100%' : '60%' : '100%'}}>
                                        <div className="video_plyar w-100 ">
                                            <iframe
                                                src={e.url}
                                                className={"img-fluid"}
                                                frameBorder="0"
                                                style={{aspectRatio:16/9,width:'100%'}}
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center justify-content-lg-start">
                                            <div className="knowledge-bank-name text-truncate py-2">
                                                {e.title}
                                            </div>
                                        </div>
                                        <div className="date mb-3">
                                            <i className="fa-regular fa-calendar-days" aria-hidden="true"/>
                                            <span className='ps-2 '>
                                                    {moment(e.created_at, "DD MMM YYYY, hh:mm A").format("MMM D, YYYY")}
                                            </span>
                                        </div>
                                        <Link to={'/knowledge-bank/events/' + e.id}>
                                            <button type="button" onClick={scrollToTop}
                                                    className="primary-button text-nowrap ">Click To View
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            ))

                            : (recordsSearchbox.map((e,index) =>
                                <div key={index} className={isMobileView  ? "text-center col-12" :'text-start col-lg-4'}
                                     style={{marginBottom: '94px'}}>
                                        <iframe
                                            src={e.url}
                                            style={{aspectRatio:16/9,width:'100%'}}
                                            className="img-fluid"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        />

                                    <div className="d-flex justify-content-center justify-content-lg-start">
                                        <div className="knowledge-bank-name text-truncate py-2"
                                           style={{maxWidth:isMobileView ? '380px':'260px'}}>
                                            {e.title}
                                        </div>
                                    </div>
                                    <div className="date mb-3">
                                        <i className="fa-regular fa-calendar-days" aria-hidden="true"/>
                                        <span className='ps-2 '>
                                            {moment(e.created_at, "DD MMM YYYY, hh:mm A").format("MMM D, YYYY")}
                                        </span>
                                    </div>
                                    <Link to={'/knowledge-bank/events/' + e.id}>
                                        <button type="button" onClick={scrollToTop}
                                                className="primary-button text-nowrap ">Click To View
                                        </button>
                                    </Link>
                                </div>))
                        }

                    </div>
                </div>
                {searchQuery === '' ?
                    <div className="d-flex justify-content-center mb-5">
                        <Pagination count={npages} onChange={handlePage} color="primary"/>
                    </div>
                    : <div>
                        {(recordsSearchbox.length === 0) ?
                            <div className='d-flex justify-content-center p-5'>
                                <div>
                                    <div className="img-fluid d-flex justify-content-center pb-2">
                                        <img height={76}
                                             width={76}
                                             src={fileNotFoundIcon}
                                             alt=''/>
                                    </div>
                                    <h1 className='section-description-text '>Result Not Found!</h1>
                                </div>
                            </div>
                            : <div className="d-flex justify-content-center mb-5">
                                <Pagination count={npagesSearchbox} onChange={handlePage} color="primary"/>
                            </div>
                        }
                    </div>
                }
            </section>
            {renderFooter()}
        </div>
    );
}

EventView.propTypes = {};

export default EventView;
