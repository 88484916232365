import i18n from "i18next";
import {languages} from './core/models/languages';
import {APP_CONST} from './core/AppConstants';
import {initReactI18next} from "react-i18next";
import {isEmpty} from "./utils/validations";
import store from './store'

function getTansObject() {
  let activeLanguage = store.getState().appState.activeLanguage;
  let contentKeys = [
  ];
  if (isEmpty(contentKeys)) {
    return {};
  }
  let data = {};
  contentKeys.forEach(item => {
    data[item.key] = item.value[activeLanguage]
  });
  return data;
}

// the translations
const resources = {
  en: {
    translations: getTansObject()
  }
};


i18n
    .use(initReactI18next)
    .init({
        debug: true,
        lng: languages.defaultActiveLanguage,
        fallbackLng: APP_CONST.LANG_ENG, // use en if detected lng is not available
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        resources,
        ns: ["translations"],
         defaultNS: "translations",
    }).then(r =>{});

export default i18n;
