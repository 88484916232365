import React, {useRef, useState} from 'react';
import {connect} from "react-redux";
import {Link, withRouter, NavLink} from "react-router-dom";
import './header.css'
import HeaderView from "./headerView";

function Header(props) {



    return (
        <div>
            <HeaderView {...props}/>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(Header));
