import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {number} from "prop-types";
import Disclaimer from "./Disclaimer";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

function InvestView(props) {
    const {isMobileView, isXSView} = props;


    const [sipState, setSipState] = useState({
        inputMonthlyInvest: 0,
        inputSumInvest: 0,
        inputInvestYears: 0,
        inputExpectReturn: 0,
    });

    const [buildCorpusOf_Rs, setBuildCorpusOf_Rs] = useState(0);


    const getToteleCalculation = () => {
        const {inputMonthlyInvest, inputSumInvest, inputInvestYears, inputExpectReturn} = sipState;

        let toteleMonth = inputInvestYears * 12;
        let monthlyReturn = inputExpectReturn / 12 / 100;
        let monthlyReturn_A = monthlyReturn + 1;
        let totalNumberofMonths_B = Math.pow(monthlyReturn_A, toteleMonth);
        let totalNumberofMonths_AB = totalNumberofMonths_B - 1;
        let Monthly_Return_D = totalNumberofMonths_AB / monthlyReturn;
        let TotelSIPAmount = Monthly_Return_D * inputMonthlyInvest * monthlyReturn_A;
        TotelSIPAmount = Math.round(TotelSIPAmount);

        // lumsum add
        let lsAnnualReturn = inputExpectReturn / 100;
        let lsAnnualReturn_A = lsAnnualReturn + 1;
        let lsNumberofYears = Math.pow(lsAnnualReturn_A, inputInvestYears);
        let lsLumpsumAmount = lsNumberofYears * inputSumInvest;
        lsLumpsumAmount = Math.round(lsLumpsumAmount);

        setBuildCorpusOf_Rs((TotelSIPAmount + lsLumpsumAmount) || 0);
    };


    useEffect(() => {
        getToteleCalculation()
    }, [sipState.inputMonthlyInvest,
        sipState.inputInvestYears,
        sipState.inputExpectReturn,
        sipState.inputSumInvest
    ]);
    const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const removeZerofn = (number) => {
        return number.replace(/-?\b0\b|-?\d+\.\d*\b0*\b|-[1-9]\d*(\.\d+)?|\b0(\.\d+)?/g, 0);
    };

    const setInputMonthlyInvestfn = ((e) => {

        console.log('Printing value if month');
        if (e.target.value >= 0 && e.target.value <= 1000000) {
            setSipState((prevInputs) => ({
                ...prevInputs,
                inputMonthlyInvest: removeZerofn(e.target.value),
            }));
        } else {
            setSipState((prevInputs) => ({
                ...prevInputs,
                inputMonthlyInvest: 0,
            }));
        }
    });

    const setInputInvestYearsfn = ((e) => {
        if (e.target.value >= 0 && e.target.value <= 50) {
            setSipState((prevInputs) => ({
                ...prevInputs,
                inputInvestYears: removeZerofn(e.target.value),
            }));
        } else {
            setSipState((prevInputs) => ({
                ...prevInputs,
                inputInvestYears: 0,
            }));
        }
    });

    const setInputSumInvestfn = ((e) => {
        if (e.target.value >= 0 && e.target.value <= 10000000) {

            console.log('Printing value if sum invesr', sipState.inputSumInvest);
            setSipState((prevInputs) => ({
                ...prevInputs,
                inputSumInvest: removeZerofn(e.target.value),
            }));
        } else {

            console.log('Printing value else sum', sipState.inputSumInvest);
            setSipState((prevInputs) => ({
                ...prevInputs,
                inputSumInvest: 0,
            }));
        }

    });

    const setInputExpectReturnfn = ((e) => {
        if (e.target.value >= 0 && e.target.value <= 30) {
            console.log('Printing value if');
            setSipState((prevInputs) => ({
                ...prevInputs,
                inputExpectReturn: removeZerofn(e.target.value),
            }));
        } else {
            console.log('Printing value');
            setSipState((prevInputs) => ({
                ...prevInputs,
                inputExpectReturn: 0,
            }));

        }
    });

    const renderUI = () => {
        if (isMobileView) {
            return (
                <div className="">
                    <div className="container-x">
                        <div className="d-flex align-items-center mb-4"
                             style={{gap: 10}}>
                            <div style={{flex: 1}}>
                                <label className="input-label">Monthly invest</label>
                                <input value={sipState.inputMonthlyInvest}
                                       type={"number"}
                                       min={500}
                                       max={1000000}
                                       onChange={setInputMonthlyInvestfn}
                                       onBlur={e => e.target.value === '' ? setSipState(prevState => ({
                                           ...prevState,
                                           inputMonthlyInvest: 0
                                       })) : ''}
                                       className="input-field m-0"/>
                            </div>
                            <div style={{flex: 1}}>
                                <label className="input-label">Lump sum invest</label>
                                <input value={sipState.inputSumInvest}
                                       type={"number"}
                                       min={5000}
                                       max={10000000}
                                       onChange={setInputSumInvestfn}
                                       onBlur={e => e.target.value === '' ? setSipState(prevState => ({
                                           ...prevState,
                                           inputSumInvest: 0
                                       })) : ''}
                                       className="input-field"/>

                            </div>
                        </div>
                        <div className="d-flex align-items-start"
                             style={{gap: 10}}>
                            <div style={{flex: 1}}>
                                <label className="input-label">No. of Years</label>
                                <input value={sipState.inputInvestYears}
                                       type={"number"}
                                       min={1}
                                       max={50}
                                       onChange={setInputInvestYearsfn}
                                       onBlur={e => e.target.value === '' ? setSipState(prevState => ({
                                           ...prevState,
                                           inputInvestYears: 0
                                       })) : ''}

                                       className="input-field"/>
                            </div>
                            <div style={{flex: 1}}>
                                <label className="input-label">Expected Rate of Return</label>
                                <input
                                    value={sipState.inputExpectReturn}
                                    type="number"
                                    min={0} // Adjusted to start from 0
                                    max={30}
                                    onChange={setInputExpectReturnfn}
                                    onBlur={(e) => {
                                        if (e.target.value === '') {
                                            setSipState((prevState) => ({
                                                ...prevState,
                                                inputExpectReturn: 0,
                                            }));
                                        }
                                    }}
                                    className="input-field"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="bg-black-section my-3 px-4 py-3">
                        <div className="d-flex justify-content-between align-items-center sip-calc">
                            <h5>Monthly invest</h5>
                            <h1>₹ {formatNumber(sipState.inputMonthlyInvest) || 0}</h1>
                        </div>
                        <div className="d-flex justify-content-between align-items-center sip-calc">
                            <h5>Lump sum invest</h5>
                            <h1>₹ {formatNumber(sipState.inputSumInvest) || 0}</h1>
                        </div>
                        <div className="d-flex justify-content-between align-items-center sip-calc">
                            <h5>Resultant Amount</h5>
                            <h1>₹ {formatNumber(buildCorpusOf_Rs) || 0}</h1>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="btn btn-light btn-lg  mt-5 font-bolder w-50 ">
                                <Link to='/contact-us'>Invest Now</Link>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center ">
                        <span className="py-2 view-disclaimer-text" data-bs-toggle="modal"
                              data-bs-target="#exampleModal">View Disclaimer</span>
                    </div>

                </div>

            )
        }
        return (
            <div className="container-x h-100 mb-5 pb-2">
                <div className="row">
                    <div className="col-lg-6 col-12 ">
                        <div className='row'>
                            <div className="col-5">
                                <div className="w-100 mb-4">
                                    <label className="input-label">Monthly invest</label>
                                    <div className="slider-position">
                                        <span className="slider-css"/>
                                        <input value={sipState.inputMonthlyInvest === '' ? 0 : sipState.inputMonthlyInvest}
                                               style={{
                                                   background: `linear-gradient(to right, #757575 ${((sipState.inputMonthlyInvest - 500) / (1000000 - 500)) * 100}%, #ddd ${((sipState.inputMonthlyInvest - 500) / (1000000 - 500)) * 100}%)`,
                                               }}
                                               onChange={e => setSipState(prevState => ({
                                                   ...prevState,
                                                   inputMonthlyInvest: e.target.value
                                               }))}
                                               name="llsslumSumAmount" type="range"
                                               min="0"
                                               max="1000000"/>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span className="range-span-text">500</span>
                                        <span className="range-span-text">10L</span>
                                    </div>
                                    <div>
                                        <input value={sipState.inputMonthlyInvest}
                                               type={"number"}
                                               min={500}
                                               max={1000000}
                                               onChange={setInputMonthlyInvestfn}
                                               onBlur={e => e.target.value === '' ? setSipState(prevState => ({
                                                   ...prevState,
                                                   inputMonthlyInvest: 0
                                               })) : ''}/>
                                    </div>

                                </div>

                                <div className="w-100">
                                    <label className="input-label">No. of Years</label>
                                    <div className="slider-position">
                                        <span className="slider-css"/>
                                        <input id="rtrange"
                                               value={sipState.inputInvestYears === '' ? 0 : sipState.inputInvestYears}
                                               type="range"
                                               style={{
                                                   background: `linear-gradient(to right, #757575 ${((sipState.inputInvestYears - 1) / (50 - 1)) * 100}%, #ddd ${((sipState.inputInvestYears - 1) / (50 - 1)) * 100}%)`,
                                               }}
                                               onChange={e => {
                                                   setSipState(prevState => ({
                                                       ...prevState,
                                                       inputInvestYears: e.target.value
                                                   }))
                                               }}
                                               min="0"
                                               max="50"/>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span className="range-span-text">1 Year</span>
                                        <span className="range-span-text">50 Year</span>
                                    </div>
                                    <div>
                                        <input value={sipState.inputInvestYears}
                                               type={"number"}
                                               min={1}
                                               max={50}
                                               onChange={setInputInvestYearsfn}
                                               onBlur={e => e.target.value === '' ? setSipState(prevState => ({
                                                   ...prevState,
                                                   inputInvestYears: 0
                                               })) : ''}/>
                                    </div>

                                </div>
                            </div>

                            <div className='col-1  d-flex justify-content-center m-0 p-0'>
                                <div className="h-25 d-flex align-items-center">
                                    <div className='and-symbol rounded-circle d-flex justify-content-center '
                                         style={{backgroundColor: "#c4c4c4"}}>
                                        <p style={{color: "white"}}>&</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-5 col-6'>
                                <div className="w-100 mb-4">
                                    <label className="input-label">Lump sum invest</label>
                                    <div className="slider-position">
                                        <span className="slider-css"/>
                                        <input value={sipState.inputSumInvest === '' ? 0 : sipState.inputSumInvest}
                                               style={{
                                                   background: `linear-gradient(to right, #757575 ${((sipState.inputSumInvest - 5000) / (10000000 - 5000)) * 100}%, #ddd ${((sipState.inputSumInvest - 5000) / (10000000 - 5000)) * 100}%)`,
                                               }}
                                               onChange={setInputSumInvestfn}
                                               name="llsslumSumAmount"
                                               type="range"
                                               min="0"
                                               max="10000000"/>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span className="range-span-text">5K</span>
                                        <span className="range-span-text">1Cr</span>
                                    </div>
                                    <div>
                                        <input value={sipState.inputSumInvest}
                                               type={"number"}
                                               min={5000}
                                               max={10000000}
                                               onChange={setInputSumInvestfn}
                                               onBlur={e => e.target.value === '' ? setSipState(prevState => ({
                                                   ...prevState,
                                                   inputSumInvest: 0
                                               })) : ''}/>
                                    </div>
                                </div>


                                <div className="w-100">
                                    <label className="input-label">Expected Rate of Return</label>
                                    <div className="slider-position">
                                        <span className="slider-css"/>
                                        <input value={sipState.inputExpectReturn === '' ? 0 : sipState.inputExpectReturn}
                                            style={{
                                                background: `linear-gradient(to right, #757575 ${((sipState.inputExpectReturn) / (30)) * 100}%, #ddd ${((sipState.inputExpectReturn) / (30)) * 100}%)`,
                                            }}
                                            onChange={setInputExpectReturnfn}
                                            name="llsslumSumAmount"
                                            type="range"
                                            min="0"
                                            max="30"
                                        />
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span className="range-span-text">5%</span>
                                        <span className="range-span-text">30%</span>
                                    </div>
                                    <div>
                                        <input value={sipState.inputExpectReturn}
                                               type="number"
                                               min={0} // Adjusted to start from 0
                                               max={30}
                                               onChange={setInputExpectReturnfn}
                                               onBlur={(e) => {
                                                   if (e.target.value === '') {
                                                       setSipState((prevState) => ({
                                                           ...prevState,
                                                           inputExpectReturn: 0,
                                                       }));
                                                   }
                                               }}  />
                                    </div>
                                </div>

                            </div>

                            <div>
                                <button className="btn btn-outline-dark my-4"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal">View Disclaimer
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-12">
                        <div className="bg-black-section p-2 text-center h-100 d-flex flex-column justify-content-center mb-3">
                            <div className="d-flex mb-3">
                                <div className="sip-calc" style={{flex: 1}}>
                                    <h5>Monthly invest</h5>
                                    <h1>₹ {formatNumber(sipState.inputMonthlyInvest) || 0}</h1>
                                </div>
                                <div className="sip-calc" style={{flex: 1}}>
                                    <h5>Lump sum invest</h5>
                                    <h1>₹ {formatNumber(sipState.inputSumInvest) || 0}</h1>
                                </div>
                            </div>
                            <div className="sip-calc">
                                <h5>Resultant Amount</h5>
                                <h1>₹ {formatNumber(buildCorpusOf_Rs) || 0}</h1>
                            </div>
                            <div className="d-flex justify-content-center">
                            <div className="btn btn-light btn-lg  mt-5 font-bolder">
                                <Link to='/contact-us'>Invest Now</Link>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade"
                         id="exampleModal"
                         aria-labelledby="myLargeModalLabel"
                         aria-hidden="true">
                        <Disclaimer/>
                    </div>
                </div>
            </div>
        )
    };


    return (
        <>
            {renderUI()}
        </>
    );
}


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});


export default connect(mapStateToProps, {})(withRouter(InvestView));

