
const API_URL = process.env.REACT_APP_API_URL;

export const contactUs=(event)=>{
    let url=API_URL+"/service/simplicityService/contactUs/"
    return fetch(url,{
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            name: event.name,
            email: event.email,
            phoneNumber: event.phoneNumber,
            website: event.website,
            description: event.description,
            // token: event.token,
        })
    })
}
