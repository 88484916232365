import React, {useRef, useState, useEffect} from 'react';
import MutualFundView from "./MutualFundView";


function MutualFund(props) {
    const [mutualFundState, srtMutualFundState] = useState({
        Products: [],

        videoLink: '',
        errMsg: 'Found error!',
        isError: false,
        isLoading: true,
        allData: [],
        metaDetails: [
            {name: 'keywords', content: 'VR Local keywords'},
            {name: 'description', content: 'index, follow'},
            {name: 'tag', content: 'VR Local'}
        ],
        productByid: 'string',
    });

    return (
        <div>
            <MutualFundView/>
        </div>
    );
}

export default MutualFund;
