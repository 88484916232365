import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


function MutualFundView(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    return (
        <div>
            <div className='topsection'/>
            <div className="main-container pt-lg-4 pt-0">
                <div className="container-x">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="page-title mb-3">
                                Mutual funds
                            </div>
                            <div className="page-sub-title mb-3">
                                The leading wealth building
                                tool in the world
                            </div>
                            <div className="img-block px-5 d-lg-none d-block">
                                <img src={s3BaseURL + '/homepage/estatePlanning.jpg'}
                                     className="img-fluid bannerImg mb-3"
                                     alt=""/>
                            </div>
                            <div className="content mb-3">
                                Mutual funds are simple, cost-effective, transparent, and tax-efficient.
                            </div>
                            <div className="content mb-3">
                                The Indian mutual fund industry has grown substantially in the last 20 years,
                                and today
                                there are around 45 mutual fund asset management companies, offering more than
                                5,000
                                different options to Indian investors.
                            </div>
                            <div className="content mb-3">
                                Simplicity has extensive knowledge and experience in mutual fund investing, in
                                India and international markets. We create unique baskets of funds for each family, and
                                make sure that no more than 8-10 are selected – with no overlapping stocks. We diversify across
                                different sectors and geographies to create and manage long-term wealth, with an emphasis
                                on risk management.
                            </div>
                        </div>
                        <div className=" col-lg-6 col-12">
                            <div className="img-block px-5 d-lg-block d-none">
                                <img src={s3BaseURL + '/products/mutualFunds.jpg'}
                                     className="img-fluid bannerImg"
                                     alt="image"/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {renderFooter()}
        </div>
    );
}

export default MutualFundView;
