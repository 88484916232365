import React, {useEffect} from 'react';
import {Link, withRouter, NavLink} from "react-router-dom";
import Footer from "../../core/layout/footer/footer";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


function InternationalInvestmentsView(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    return (
        <div>
            <div className="topsection"/>
            <div className="main-container pt-lg-4 pt-0">
                <div className="container-x">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="page-title mb-3">
                                International Investments
                            </div>
                            <div className="page-sub-title mb-3">
                                Investing in companies that operate in more than 10 countries and are leaders in
                                innovation
                            </div>
                            <div className="img-block px-5 d-lg-none d-block">
                                <img src={s3BaseURL + '/homepage/internationalInvestment.jpg'}
                                     className="img-fluid bannerImg mb-3"
                                     alt=""/>
                            </div>
                            <div className="content mb-3">
                                The global standard is that you should invest 70%
                                of
                                your financial assets in the country in which you are
                                going retire. The remaining 30% should be invested
                                internationally.
                            </div>
                            <div className="content mb-3"> Since the day of its inception, Simplicity has
                                been
                                investing for its clients in the US and other developed
                                global markets.
                            </div>
                            <div className="content mb-3">Investing internationally requires
                                specialized knowledge on global economies, foreign
                                currencies, international taxation, and a very proactive
                                approach to managing risk.
                            </div>
                            <div className="contact-us-text mb-3">Get in touch with us
                                <a href="/contact-us" className="contact-us">Click Here</a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="img-block px-5 d-lg-block d-none">
                                <img src={s3BaseURL + '/homepage/internationalInvestment.jpg'}
                                    className='img-fluid bannerImg'
                                    alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {renderFooter()}

        </div>
    );
}

export default InternationalInvestmentsView;
