import Helmet from "react-helmet";
import {isEmpty} from "./validations";

const API_URL = process.env.REACT_APP_API_URL;

const seoMetaData = [
    {
        "page": "Home page",
        "url": "/",
        "title": "Home Page | Simplicity Investing is managed by people who have worked in the field of investments for their entire lives. We use our global knowledge to predict the future of commerce to create wealth for our clients .",
        "metaTags": [
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    },
    {
        "page": "About us",
        "url": "/about-us",
        "title": "About Us | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    },
    {
        "page": "Contact us",
        "url": "/contact-us",
        "title": "Contact Page | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    },
    {
        "page": "Services - Retirement Investments",
        "url": "/services/retirement-investments",
        "title": "Retirement Investments | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    },
    {
        "page": "Services - Wealth Creation",
        "url": "/services/wealth-creation",
        "title": "Wealth Creation | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    },
    {
        "page": "Services - Risk Management",
        "url": "/services/risk-management",
        "title": "Risk Management | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Services - International Investments ",
        "url": "/services/international-investments",
        "title": "International Investments | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Services - International Investments ",
        "url": "/services/international-investments",
        "title": "International Investments | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Services - Global Tax Advisory",
        "url": "/services/global-tax-advisory",
        "title": "Global Tax Advisory | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Services - Estate Planning",
        "url": "/services/estate-planning",
        "title": "Estate Planning | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Services - Treasury Operations for Corporates",
        "url": "/services/treasury-operations-for-corporates",
        "title": "Treasury Operations for Corporates | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Process",
        "url": "/#process-chart",
        "title": "Home Page | Simplicity Investing is managed by people who have worked in the field of investments for their entire lives. We use our global knowledge to predict the future of commerce to create wealth for our clients .",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Products - Mutual funds",
        "url": "/products/mutual-funds",
        "title": "Mutual funds | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Products - Stocks",
        "url": "/products/stocks",
        "title": "Stocks | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Select Your Profile - Business",
        "url": "/select-your-profile/business",
        "title": "Need financial backup for your business cycles? | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Select Your Profile - Get better control of your future",
        "url": "/select-your-profile/for-her",
        "title": "Get better control of your future | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Select Your Profile - Global",
        "url": "/select-your-profile/global",
        "title": "Investment assets in different countries ? | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Select Your Profile - New Gen",
        "url": "/select-your-profile/new-gen",
        "title": "Young investor? <br/> Be independent by your early 40’s | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Select Your Profile - Professional",
        "url": "/select-your-profile/professional",
        "title": "Widen your wealth and estate landscape | Simplicity-Investing",
        "metaTags": [

            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Select Your Profile - Salaried",
        "url": "/select-your-profile/salaried",
        "title": "Take care of everyone in your family | Simplicity-Investing",
        "metaTags": [

            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Select Your Profile - Retiree",
        "url": "/select-your-profile/retiree",
        "title": "Retired? Create a paycheck for yourself | Simplicity-Investing",
        "metaTags": [

            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Knowledge Bank - Event",
        "url": "/knowledge-bank/events",
        "title": "Events Page | Simplicity-Investing",
        "metaTags": [

            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Knowledge Bank - Blog",
        "url": "/knowledge-bank/articles",
        "title": "Blogs Page | Simplicity-Investing",
        "metaTags": [

            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Knowledge Bank - White Papers",
        "url": "/knowledge-bank/publications",
        "title": "White Papers Page | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "SIP",
        "url": "/sip",
        "title": "SIP CALCULATOR | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }, {
        "page": "Sign Up",
        "url": "/sign-up",
        "title": "Sign Up Page | Simplicity-Investing",
        "metaTags": [
            {
                SEOMasterType: "name",
                SEOMasterKey: "keywords",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "description",
                value: "undefined",
            },
            {
                SEOMasterType: "name",
                SEOMasterKey: "tag",
                value: "undefined",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:site_name",
                value: "Simplicity-investing",
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:url",
                value: "https://www.simplicityinvesting.com"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:image",
                value: "assets/home-page.png"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:title",
                value: "simplicity | Home Page"
            },
            {
                SEOMasterType: "property",
                SEOMasterKey: "og:description",
                value: "Simplicity Investing is managed by people who have worked in the field of investments for their entire lives."
            }
        ]
    }
]

export const getMetaTagUi = (pathname) => {

    // console.log("checking pathname",pathname);
    if (pathname === '' || pathname === '/' || pathname === '/home') {
        pathname = '/'
    }


    let prefix = "";

    if (process.env.REACT_APP_ENVIRONMENT === 'QA') {
        prefix = "QA ";
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT') {
        prefix = "DEV ";
    }



    let currentPageMetaData = seoMetaData.find(event=>{
                                                                if(pathname===event.url)
                                                                {
                                                                    return event;
                                                                }
                                                            });

    let SEOAttributes = [];
    if(!currentPageMetaData)
    {
        return;
    }
    else{
    SEOAttributes=currentPageMetaData.metaTags;
    }
    let SEONameAttributes = [];
    let SEOPropertyAttributes = [];

    SEOAttributes.map((obj) => {
        if (obj.SEOMasterType === 'name') {
            SEONameAttributes.push(obj);
        } else if (obj.SEOMasterType === 'property') {
            SEOPropertyAttributes.push(obj);
        }
    })

    return (
        <Helmet>
            <title>{`` + prefix + currentPageMetaData.title}</title>
            {
                SEONameAttributes.map((obj) => {
                    return (
                        <meta key={obj?.SEOMasterKey} name={obj?.SEOMasterKey} content={obj?.value}/>
                    );
                })
            }
            {
                SEOPropertyAttributes.map((obj) => {
                    return (
                        <meta key={obj?.SEOMasterKey} property={obj?.SEOMasterKey} content={obj?.value}/>
                    );
                })
            }
        </Helmet>
    );

}
