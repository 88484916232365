import React from 'react';

function Disclaimer(props) {
    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title"
                        id="exampleModalLabel">
                        Disclaimer
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                </div>
                <div className="modal-body">
                    <p>Mutual Fund investments are subject to market risks, read all scheme related
                        documents carefully. The NAVs of the schemes may go up or down depending upon the
                        factors and forces affecting the securities market including the fluctuations in the
                        interest rates. The past performance of the mutual funds is not necessarily
                        indicative of future performance of the schemes. The Mutual Fund is not guaranteeing
                        or assuring any dividend under any of the schemes and the same is subject to the
                        availability and adequacy of distributable surplus. Investors are requested to
                        review the prospectus carefully and obtain expert professional advice with regard to
                        specific legal, tax and financial implications of the investment/participation in
                        the scheme.</p>
                    <p> All information contained in this document and any attachments hereof, are
                        confidential in nature. It is intended only for the individual or legal corporate
                        entity to whom it is addressed and must not be disclosed to anyone else without
                        express written consent from Simplicity Investing India Pvt Ltd. If you have
                        erroneously received this message, please immediately delete it and notify the
                        sender. No part of this document should be copies or reproduced without prior
                        permission of the company.</p>
                    <p>For recipients who are United States Persons as defined under the extant laws of the
                        United States of America and residents of Canada : This document is being sent to
                        you solely for the purpose of responding to your queries / service request / DDQs in
                        connection with your investments made prior to July 21, 2014 and any investments
                        made subsequently through Simplicity Investing India Pvt Ltd, when you were
                        physically present in India. This document or the contents thereof shall not be
                        construed as an advertisement, endorsement, solicitation, financial advice, or offer
                        to sell any products or services of Simplicity Investing India Pvt Ltd. </p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Disclaimer;