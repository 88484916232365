import React, {useEffect} from 'react';
import {Link, withRouter, NavLink} from "react-router-dom";
import Footer from "../../core/layout/footer/footer";


let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


function RetirementPlanningView(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    return (
        <div>
            <div className="topsection"/>
            <div className="main-container pt-lg-4 pt-0">
                <div className="container-x">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="page-title mb-3">
                                Retirement Investments
                            </div>
                            <div className="page-sub-title mb-3">
                                How much money do I need to be financially independent?
                            </div>
                            <div className="img-block px-5 d-lg-none d-block">
                                <img src={s3BaseURL + '/homepage/retirementInvestments.jpg'}
                                     className="img-fluid bannerImg mb-3"
                                     alt=""/>
                            </div>
                            <div className="content mb-3">
                                The amount you will need depends on the place you’re living and the number of
                                dependents. For example, a family of four living in a metro city would need up
                                to Rs. 3 crores of wealth accumulation in order to retire and maintain a good
                                standard of living.
                            </div>
                            <div className="content mb-3">
                                This assumes no mortgage liabilities or debts at the time of retirement.
                            </div>
                            <div className="content mb-3">
                                Families living in non-metro cities would require less, while those living
                                abroad would require more.
                            </div>
                            <div className="contact-us-text mb-3">Get in touch with us
                                <a href="/contact-us" className="contact-us">Click Here</a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="img-block px-5 d-lg-block d-none">
                                <img src={s3BaseURL + '/homepage/retirementInvestments.jpg'}
                                     className="img-fluid bannerImg"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {renderFooter()}
        </div>
    );
}

export default RetirementPlanningView;
