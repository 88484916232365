import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../assets/css/HomePage.css"

function SelectYourProfileCarousal(props) {

    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1024, min: 548},
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 548, min: 0},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    return (
        <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={false}
            customRightArrow={<div></div>}// means to render carousel on server-side.
            customLeftArrow={<div></div>}// means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            transitionDuration={1000}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
        >

            <div className="card card-content carousel-gap h-100">
                <div className="card-text-padding">
                    I rate the service quality of Simplicity at international standards with an Indian touch. I wanted
                    to
                    buy a business but did not have enough cash to make the bid. Simplicity helped me in managing the
                    surplus cash flow from current business and accumulate the savings which earned a decent tax free
                    income. Within 4 years I had enough capital to make a bid for the new business venture.
                </div>
                <div className="carousel-footer-container "
                     style={{backgroundColor: "rgb(255, 192, 0)"}}>
                    <div className="carousal-footer-text white">-Business</div>
                </div>
            </div>

            <div className="card card-content carousel-gap h-100">
                <div className="card-text-padding">
                    I am a single woman and was referred to Simplicity in 2014, for securing my future. In the last 7
                    years, my experience with them is that they are consistent in their advice and follow-up. They
                    always share their knowledge and take the time to explain why they are advising particular
                    investments. I am on my way to financial independence.
                </div>
                <div className="carousel-footer-container "
                     style={{backgroundColor: "rgb(112, 48, 160)"}}>
                    <div className="carousal-footer-text white">-For Her</div>
                </div>
            </div>

            <div className="card card-content carousel-gap h-100">
                <div className="card-text-padding">
                    After living abroad for 40 years, we moved to India in 2018 to retire. We were referred to
                    Simplicity by one of my friends. In the last 3 years, our experience with Simplicity has been the
                    best as compared to other advisors. We are now consolidating all investments in India and Abroad.
                    The returns and the quality of the investments far exceeded our expectations. They also help us with
                    our global taxes.
                </div>
                <div className="carousel-footer-container "
                     style={{backgroundColor: "rgb(68, 114, 196)"}}>
                    <div className="carousal-footer-text white">-Global</div>
                </div>
            </div>

            <div className="card card-content carousel-gap h-100">
                <div className="card-text-padding">
                    Most of my friends and I were investing in Bitcoins, momentum-based stocks, and short-term trading.
                    I attended one of Simplicity’s seminars in which they were sharing information on long-term,
                    disciplined investing. I started small and then I began to see the difference between Simplicity’s
                    results and those of my own. So I consolidated everything with Simplicity. This is the best thing
                    that has happened to me in the last 10 years.
                </div>
                <div className="carousel-footer-container "
                     style={{backgroundColor: "rgb(112, 173, 71)"}}>
                    <div className="carousal-footer-text white">-New Gen</div>
                </div>
            </div>

            <div className="card card-content carousel-gap h-100">
                <div className="card-text-padding">
                    As a medical professional, my investments were scattered across multiple products that impacted my
                    returns. The Simplicity team, helped restructure my portfolio and enabled me to focus on my career.
                    Today, thanks to Simplicity, I feel secure about my long-term retirement goals.
                </div>
                <div className="carousel-footer-container "
                     style={{backgroundColor: "rgb(91, 155, 213)"}}>
                    <div className="carousal-footer-text white">-Professional</div>
                </div>
            </div>

            <div className="card card-content carousel-gap h-100">
                <div className="card-text-padding">
                    I came to know about Simplicity while traveling from Mumbai to Pune on a bus. In my first formal
                    meeting, I was told that I can build a portfolio of Rs. 1 crore through mutual funds in 5 years. I
                    did not believe it, but based on their conviction, I started the process of saving and investing To
                    my surprise, I am close to accomplishing that Rs. 1 crore milestone this year without taking any
                    undue risk.
                </div>
                <div className="carousel-footer-container "
                     style={{backgroundColor: "rgb(192, 0, 0)"}}>
                    <div className="carousal-footer-text white">-Salaried</div>
                </div>
            </div>

            <div className="card card-content carousel-gap h-100">
                <div className="card-text-padding">
                    Being of a conservative mindset, I always felt comfortable investing in safe instruments like fixed
                    deposits. The Simplicity team spent a long time educating me about the pros and cons of investing in
                    debt and equity mutual funds. After more than 6 months, I was comfortable investing a small portion
                    of my money in debt mutual funds. Today, I am convinced about my decision as these products are safe
                    while they also optimize my income tax.
                </div>
                <div className="carousel-footer-container "
                     style={{backgroundColor: "rgb(237, 125, 49)"}}>
                    <div className="carousal-footer-text white">-Retiree</div>
                </div>
            </div>
        </Carousel>
    );
}

export default SelectYourProfileCarousal;
