import React from 'react';
import ProcessImgMobile from "../assets/img/Homepage/processImgMobile.png";
import "../assets/css/HomePage.css"
import Footer from "../core/layout/footer/footer";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function ProcessView(props) {
    const {isMobileView, isXSView} = props;


    return (
        <>
            <div className="topsection"/>
            <div className="section-padding-bottom">
                <div className="section-title text-center px-3">
                    How we create and manage wealth
                </div>
                <div className="section-description-text section-description-padding text-center px-1 mx-1 px-md-5 mx-md-5">
                    Simplicity’s core strength is in depth knowledge on global financial markets and
                    the ability to connect this expertise with different client profiles
                </div>
                <div>
                    {isMobileView ?
                        <div className="text-center">
                            <img src={ProcessImgMobile}
                                 className="img-fluid " alt=""
                            />
                        </div>
                        :
                        <img src={s3BaseURL + '/homepage/manageWealth.jpg'}
                             alt=""
                             style={{width: "100%", marginTop: "20px"}}
                        />
                    }
                </div>
            </div>
            <Footer {...props}/>
        </>
    );
}

ProcessView.propTypes = {};

export default ProcessView;
