const appTheme = {
    appColor: {
        primary: '#1E53BD',
        darkBlue: '#0B407D',
        secondary: '#FEA133',
        success: '#198754',
        warning: '#EBB666',
        danger: '#D72F2F',
        gray: '#8B8B8B',
        darkGray: '#575757',
        grey: '#c5c5c5',
        black: '#333333',
        white: '#fff',
        info: '#03a9f4'
    },
    linkStyle: {
        color: '#0073ff'
    },
    default: {
        fontSize: 14,
        fontFamily: "'Poppins', sans-serif",
        // fontFamily: "Barlow",
        btnBorderType: 'square'

    },
};

export default appTheme;
