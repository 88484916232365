import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import "../../assets/css/SelectYourProfile.css";
import {Button} from "react-bootstrap";
import Footer from "../../core/layout/footer/footer";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


function RetireeView(props) {
    const [showUI, setShowUI] = useState(false);
    const {isMobileView, isXSView} = props;


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const displayUI = () => {
        setShowUI(true);
        window.scrollTo(0, 0)
    };

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    return (
        <div>
            <div className='topsection'/>
            {!showUI &&
            <div className="container-x">
                <div className="bg-retire">
                    <div className="head-block-select-para">
                        <div className="page-title text-center my-3">
                            Retired? Create a paycheck for yourself
                        </div>
                        <div className="para-select-profile mb-4">
                            Develop a plan for steady cash flow from the investments, to enjoy your
                            retirement
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button className="secondary-button icon-burnt-orange"
                                    onClick={displayUI}>
                                READ MORE </Button>
                        </div>
                    </div>
                </div>
                {isMobileView ?
                    <div>
                        <img src={s3BaseURL + '/selectYourProfile/retireePrimaryBg.jpg'}
                             className="img-fluid bannerImg "
                             alt=""/>
                    </div>
                    :
                    <div/>
                }
            </div>
            }

            {showUI &&
            <div className="container-x"
                 style={{height: isMobileView ? '' : "calc(100dvh - 140px)"}}>
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="page-title my-3">
                            Going from pay-checks
                            to play-checks
                        </div>
                        <div className="content mb-3">
                            You have worked many years, and now is the time to enjoy
                            life. In this phase, it’s important to avoid high risk.
                            Examples of risk that may surprise you include being over
                            invested in debt products and under invested in global
                            investments. And taking investment advice from your
                            friends and family is also risky.
                        </div>
                        <div className="content mb-3">
                            At this stage in your life, it’s also likely that you need to
                            consolidate many assets that you’ve acquired, and need to
                            assign funds to your next generation.
                        </div>
                        <div className="content mb-3">
                            We will work with you to design a portfolio that not only
                            considers your current situation, but also the goals you
                            have for making your life more enjoyable.
                        </div>
                        <div className="contact-us-text mb-3">Get in touch with us
                            <a href="/contact-us" className="contact-us">Click Here</a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="d-none d-lg-block">
                            <div className="d-flex justify-content-center">
                                <img src={s3BaseURL + '/selectYourProfile/retireeSecondaryBg.jpg'}
                                     className="img-fluid img img-container"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {renderFooter()}
        </div>
    );
}

export default RetireeView;
