import React, {useState} from 'react';
import ForHerView from "./ForHerView";
import {connect} from "react-redux";
import {Link, withRouter, NavLink} from "react-router-dom";

function ForHer(props) {
    const[forHer,setForHer] = useState(
        {
            profile:[],
            businessValue:'',
            sectiontop:true,
            section:false
        }
    );
    return (
        <ForHerView {...props}/>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(ForHer));
