import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


function EstatePlanningView(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    return (
        <div>
            <div className="topsection"/>
            <div className="main-container pt-lg-4 pt-0">
                <div className="container-x">
                    <div className="row">
                        <div className="col-lg-6  col-12">
                            <div className="page-title mb-3">
                                Estate Planning
                            </div>
                            <div className="page-sub-title mb-3">
                                Leaving a legacy for your next generation
                            </div>
                            <div className="img-block px-5 d-lg-none d-block">
                                <img src={s3BaseURL + '/homepage/estatePlanning.jpg'}
                                     className="img-fluid bannerImg mb-3"
                                     alt=""/>
                            </div>
                            <div className="content mb-3">Today, most families have at least one member
                                living overseas, and these global Indians have assets
                                in different countries.
                            </div>
                            <div className="content mb-3">
                                Having a clear estate plan is critically important in
                                order to avoid a long legal process for the next
                                generation to access the wealth. It is estimated that
                                between 10% and 15% of global wealth remains
                                unclaimed and goes to the government due to the
                                lack of estate planning.
                            </div>
                            <div className="content mb-3">
                                Simplicity works with you, along with legal
                                professionals, to create a
                                comprehensive estate plan.
                            </div>
                            <div className="contact-us-text mb-3">Get in touch with us
                                <a href="/contact-us" className="contact-us">Click Here</a>
                            </div>
                        </div>
                        <div className=" col-lg-6 col-12">
                            <div className="img-block px-5 d-lg-block d-none">
                                <img src={s3BaseURL + '/homepage/estatePlanning.jpg'}
                                     className="img-fluid bannerImg"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {renderFooter()}
        </div>
    );
}

export default EstatePlanningView;
