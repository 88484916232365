import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ProcessView from "./ProcessView";


function Process(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <ProcessView {...props}/>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});


export default connect(mapStateToProps, {})(withRouter(Process));
