import React, {useEffect, useState} from 'react';
import StockView from "./StockView";

function Stock(props) {

    return (
        <div>
            <StockView/>
        </div>
    );
}

export default Stock;