import RetirementPlanningView from "./RetirementPlanningView";
import React, {useRef, useState, useEffect} from 'react';
import {retirementPlanning} from "./RetirementPlanningModule";

function RetirementPlanning(props) {
    const [retirementPlanning, setRetirementPlanning] = useState({
        serviceHeading: '',
        servicedata: [],
        iconbutton: '',
        nextsection: false

});
    useEffect(()=>{
        let retirementPlanningData = retirementPlanning;
        console.log('ab', retirementPlanningData)
    });

    return (
        <RetirementPlanningView/>
    );
}

export default RetirementPlanning;
