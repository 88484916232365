import React, {useState} from 'react';
import ContactUsView from "./ContactUsView"
import {connect} from "react-redux";

import {withRouter} from "react-router-dom";

function ContactUs(props) {
    const [homeState, setHomeState] = useState({

        siteKey: '',
        public: '',
        number: '',
        submitted: false,
        addressObj: {},
        value: '',
        data: [],
        location_Id: '',
        locationList: [],
        contactList: [],
        contactForm: 'FormGroup',
        isFormValid: false,
        recaptchaV3: 'Subscription',
        token: '',
        locationId: '',
        personName: "",
        isSubmittingForm: false,
        messageResponse: [],
        formData: new FormData(),
    });


    return (
        <ContactUsView {...props}/>
    );
}


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});


export default connect(mapStateToProps, {})(withRouter(ContactUs));

