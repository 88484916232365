import React from 'react';
import data from "../EventModule.json"
import {useParams} from "react-router-dom";
import ShowMoreEvent from "./ShowMoreEvent";
import moment from "moment";

function EventSubView(props) {
    const { id } = useParams();
    const element = data.filter(e => {
        if (e.id == id) {
            console.log(e.id);
            return e
        }
    });

    return (
        <div className='container h-100vh'>
            <div className="topsection"/>
            <section>
                <div className='mt-3 mb-5'>
                    {element.map((e, index) =>
                        <div key={index}>
                            <div className='page-title py-3'>{e.title}</div>
                            <div className='date mb-3'>
                                <i className="fa-regular fa-calendar"/>
                                <span className="px-2">
                                    {moment(e.created_at, "DD MMM YYYY, hh:mm A").format("MMM D, YYYY")}
                                </span>
                            </div>
                            <div className='w-100'
                                 style={{aspectRatio: 16 / 9}}>
                                <iframe className=" w-100"
                                        style={{height: "100%"}}
                                        src={e.url}
                                />
                            </div>
                        </div>
                    )
                    }
                </div>
                <ShowMoreEvent/>
            </section>
        </div>
    );
}

export default EventSubView;
