import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function TreasuryOperationsView(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    return (
        <div>
            <div className="topsection"/>
            <div className="pt-md-4 pt-0">
                <div className="container-x">
                    <div className="row">
                        <div className="col-lg-6  col-12">
                            <div className="page-title mb-3">
                                Treasury Operations for Corporates
                            </div>
                            <div className="page-sub-title mb-3">
                                Efficient management of your surplus cash flow
                            </div>
                            <div className="img-block px-5 d-lg-none d-block">
                                <img src={s3BaseURL + '/homepage/treasuryOperationForCorporates.jpg'}
                                     className="img-fluid bannerImg mb-3"
                                     alt=""/>
                            </div>
                            <div className="section-header mb-3">
                                Simplicity will create a vision for this savings.
                                Examples of visions are:
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-6 col-12">
                                    <div className="d-flex align-items-start row ">
                                        <div className="col-2 ">
                                            <img src={s3BaseURL + '/logo/treasuryThreeYear.jpg'}
                                                 className="img-fluid"
                                                 alt=""/>
                                        </div>
                                        <p className="content mb-3 col-9">Create an investment portfolio which covers
                                            3-5
                                            years of the
                                            company’s payroll
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="d-flex align-items-start row">
                                        <div className="imageb col-2">
                                            <img src={s3BaseURL + '/logo/treasuryFiveYear.jpg'}
                                                 className="img-fluid"
                                                 alt=""/>
                                        </div>
                                        <p className="content mb-3 col-9">Create an investment portfolio which after 5
                                            years will be able
                                            to meet the CapEx requirements for setting up a new factory
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="content mb-3">Businesses generate surplus cash by way of profits
                                which at times
                                are not required to be invested back into the business. Just like
                                individuals, businesses also need to develop the habit of savings
                                from the business profits every month.
                            </div>
                            <div className="content mb-3">These savings need to be
                                professionally managed so that it can come handy during the
                                downturn in your business cycle.
                            </div>
                            <div className="contact-us-text mb-3">Get in touch with us
                                <a href="/contact-us" className="contact-us">Click Here</a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="img-block px-5 d-lg-block d-none">
                                <img src={s3BaseURL + '/homepage/treasuryOperationForCorporates.jpg'}
                                     className="img-fluid bannerImg"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {renderFooter()}
        </div>
    );
}

export default TreasuryOperationsView;
