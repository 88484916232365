import React, {useEffect, useState} from 'react';
import "../../assets/css/SelectYourProfile.css";
import {Button} from "react-bootstrap";
import Footer from "../../core/layout/footer/footer";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function NewGenView(props) {
    const [showUI, setShowUI] = useState(false);
    const {isMobileView, isXSView} = props;


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const displayUI = () => {
        setShowUI(true);
        window.scrollTo(0, 0)
    };

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    return (
        <div>
            <div className='topsection'/>
            {!showUI &&
            <div className="container-x">
                <div className="bg-new-gen">
                    <div className="head-block-select-para">
                        <div className="page-title text-center my-3">
                            Young investor? <br/>Be independent by your early 40’s
                        </div>
                        <div className="para-select-profile mb-4">
                            Invest in innovative, next generation businesses
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button className="secondary-button icon-green"
                                    onClick={displayUI}>
                                Read More
                            </Button>
                        </div>
                    </div>
                </div>
                {isMobileView ?
                    <div>
                        <img src={s3BaseURL + '/selectYourProfile/newGenPrimaryBg.png'}
                             className="img-fluid bannerImg "
                             alt=""/>
                    </div>
                    :
                    <div/>
                }
            </div>

            }
            {showUI &&
            <div className="container-x"
                 style={{height: isMobileView ? '' : "calc(100dvh - 140px)"}}>
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="page-title my-3">
                            You have the great advantage of time
                        </div>
                        <div className="content mb-3">
                            The longer your money is put to work, the better it
                            is at creating future wealth. Investing early and
                            steadily has a great impact. There is a saying that
                            the stock market is kindest to those that stay
                            faithful to it the longest.
                        </div>
                        <div className="content mb-3">
                            And because you are young, you can take more risk,
                            investing more in companies focused in newer areas
                            like blockchain technology, space exploration,
                            biotechnology, artificial intelligence, and so on.
                        </div>
                        <div className="content mb-3">
                            Instead of investing for the short term and buying
                            and selling often, we will help you build a solid
                            portfolio that is both exciting and will provide you
                            with a wealth of income in the future.
                        </div>
                        <div className="contact-us-text mb-3">Get in touch with us
                            <a href="/contact-us" className="contact-us">Click Here</a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="d-none d-lg-block">
                            <div className="d-flex justify-content-center">
                                <img src={s3BaseURL + '/selectYourProfile/newGenSecondaryBg.jpg'}
                                     className="img-fluid img img-container"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {renderFooter()}
        </div>
    );
}

NewGenView.propTypes = {};

export default NewGenView;
