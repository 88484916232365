import React, {useEffect} from 'react';
import {redirectTo} from "../../../utils/helper";
import appTheme from "../../../assets/appTheme";
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

const PageNotFound = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const pathname = props.location.pathname;
    const origin = window.location.origin;

    return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <div>
                <div className={"pt-3 mt-2 pb-1"}>
                    <div style={{
                        lineHeight: "30px",
                        marginBottom:'0.5rem',
                        fontWeight: 600,
                        color: '#00000',
                        fontSize: '24px',
                        fontFamily: 'Barlow, Medium, sans-serif',
                        textAlign: 'center'}}>
                        Oops!
                    </div>
                    <div style={{
                        lineHeight: "30px",
                        marginBottom:'0.5rem',
                        fontWeight: 500,
                        color: '#8B8B8B',
                        fontSize: '20px',
                        fontFamily: 'Barlow, sans-serif',
                        textAlign: 'center'}}>
                        Page Not Found!
                    </div>
                    <div style={{
                        lineHeight: "30px",
                        marginBottom:'0.5rem',
                        fontWeight: 500,
                        color: '#8B8B8B',
                        fontSize: '14px',
                        fontFamily: 'Barlow, Medium, sans-serif',
                        textAlign: 'center'}}>
                        {origin+ pathname +' link is probably broken or the page has been removed.'}
                    </div>
                </div>

                <div className={"justify-content-center d-flex align-item-center"}>
                    <img src={s3BaseURL + '/images/general/pageNotFound.jpg'}
                         alt={'img'}
                         className={'img-fluid'}
                         width={'300px'}
                         height={'200px'}/>
                </div>
                <div style={{minWidth: '100px', width: 'max-content'}}
                     className=' d-grid mt-2 mx-auto text-center'>
                    <button onClick={() => redirectTo('/')}
                        style={{
                            backgroundColor: appTheme.appColor.primary,
                            color: '#FFFFFF',
                            minHeight: '38px',
                            fontSize: '14px',
                            fontWeight: 400,
                            borderRadius: '8px',
                            textTransform: 'none',
                            fontFamily: 'Barlow',
                            border: 'none',
                            minWidth: '100px',
                            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'}}>
                        Home
                    </button>
                </div>
            </div>
        </div>
    );
};

PageNotFound.propTypes = {};

export default PageNotFound;
