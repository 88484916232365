import React, {useEffect, useState} from 'react';
import Footer from "../core/layout/footer/footer";
import SelectYourProfileCarousal from "../home/SelectYourProfileCarousal"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {NavLink} from "react-router-dom";


let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

const meetUsObj = [
    {
        id: 'rashmi-shaha',
        imageUrl: s3BaseURL + '/about/rashmiKumarImg.png',
        name: 'Rashmikumar',
        fullName: 'Rashmikumar Shah',
        designation: 'Founder and CEO',
        desc1: 'Rashmikumar is a Chartered Accountant (India) and CPA (USA) with over 30 years of experience in organization building, strategic planning, and investments. He has worked in India and the USA for Fortune 500 companies and for clients from across the globe.',
        desc2: 'As a founder of Simplicity, his vision is to create a financial institution with and wealth management for different generations. Throughout his career, he has mentored many financial professionals around the world. a global knowledge base, transparency, and a focus on wealth creation'
    },
    {
        id: 'steve',
        imageUrl: s3BaseURL + '/about/steveImg.png',
        name: 'Steve',
        fullName: 'Steve Attri',
        designation: 'Founder and Marketing Director',
        desc1: 'Steve is a Founder Member and part of the management team at Simplicity.',
        desc2: 'By qualification, he is an Engineer and has an MBA in Marketing and brings the experience from working at several Fortune 500 companies.'
    },
    {
        id: 'roy',
        imageUrl: s3BaseURL + '/about/abhijeetRoy.png',
        name: 'Abhijeet',
        fullName: 'Dr. Abhijit Roy',
        designation: '',
        desc1: 'Dr. Roy leads the organizational growth strategy and expansion initiatives for Simplicity Investing. With over 30 years of experience, he has cultivated expertise in Business Development, Marketing, Operations, Systems and Processes, and Project Execution. He has a proven track record of successfully managing transformational ventures across a diverse range of industries and geographies, including Consumer Goods, Real Estate, Education, Fashion, and Financial Services, among others globally',

    },
    {
        id: 'mehta',
        imageUrl: s3BaseURL + '/about/kapilMehta.png',
        name: 'Kapil',
        fullName: 'Kapil Mehta',
        designation: '',
        desc1: 'Kapil Mehta is a seasoned professional with over 17 years of experience in Client Relationship Management within the banking and life insurance industries. He specializes in crafting personalized financial strategies, including investment management, retirement planning, and tax planning, ensuring they align with clients\' goals and risk tolerances. His client-centric approach, coupled with effective communication and people management skills, has fostered strong client trust and loyalty. Kapil consistently conducts thorough financial analyses and reviews to enhance portfolio performance, demonstrating his dedication to achieving organizational milestones.',

    },
];

const additionalDirectorObj = [
    {
        id: 'prashant',
        imageUrl: s3BaseURL + '/about/prashantDataImg.png',
        name: 'Prashant',
        fullName: 'Prashant Data',
        designation: 'Additional Director',
        desc1: 'Prashant Data is the Managing Director of Babus Laxminarayan Chiwda. Prashant comes from the prominent business families of Pune. Laxminarayan Chiwda is synonym with Pune. It has been a household name for last 75 years and the brand started resonating with every gourmet soul across the city. Prashant brings with him wealth of business experience, brand building and managing a scalable business.',

    },
    {
        id: 'sandeep',
        imageUrl: s3BaseURL + '/about/sandeepSirImg.png',
        name: 'Sandeep',
        fullName: 'Sandeep Tendulkar',
        designation: 'Additional Director',
        desc1: 'Sandeep brings over 28 years of extensive experience as an Information Technology Specialist and business leader to the forefront. With two decades spent navigating the corporate landscape, he has adeptly managed IT applications for Fortune 500 companies within the Financial Services and Insurance industry.',
        desc2: 'Transitioning into the dynamic realm of startups, Sandeep harnesses his IT acumen to tackle intricate business challenges head-on. As a founder of DeccanLogic, he is very passionate about the democratization of technology services, aiming to empower both society and businesses alike.'

    },
];

function AboutView(props) {
    const {isMobileView, isXSView} = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    function scrollToSection() {
        const section = document.getElementById('Meet-Section');
        if (section && isMobileView) {
            section.scrollIntoView({behavior: 'smooth'});
        }
    }

    function scrollToAddDirectorSection() {
        const section = document.getElementById('Additional-Director-Section');
        if (section && isMobileView) {
            section.scrollIntoView({behavior: 'smooth'});
        }
    }

    const [managementImg, setManagementImg] = useState(true);
    const [businessImg, setBusinessImg] = useState(false);
    const [researchImg, setResearchImg] = useState(false);
    const [operationImg, setOperationImg] = useState(false);
    const [activeProfile, setActiveProfile] = useState(null);
    const [activeDirector, setActiveDirector] = useState(null);


    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    return (
        <div>
            <div className="section-padding-top"/>
            <div className="container-x section-padding-bottom">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="page-title my-4">
                            Introduction
                        </div>
                        <div style={{maxWidth: "50"}}>
                            <p className="content">
                                Simplicity Investing India Private Limited is managed by an experienced and
                                professional Team that is focused on providing Clients with an exceptional
                                Investment experience. Our Team actively participates in the financial lives of our
                                Clients and guidesthem on their investment decisions with thoughtful analysis,sound
                                judgement, and relentless follow-through. We are committed to personalized service,
                                objectivity, transparency and, above all, SIMPLICITY. We began with one simple goal…
                                to partner with our Clients on a journey to Financial Freedom. We focus on the
                                future,take a global view and minimize complexity. Our approach is comprehensive and
                                holistic - and our key ingredients to a Client’s Portfolio are Strategic. Global.
                                Simple.
                            </p>
                            <p className="content">
                                <span className="font-family-itallic">
                                    Simple can be harder than complex: You have to work hard to get your thinking clean
                                    to make it simple. But it’s worth it in the end because once you get there, you can
                                    move mountains.
                                </span>-Steve Jobs
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="d-flex align-items-center justify-content-center h-100">
                            <img src={s3BaseURL + '/about/aboutUsBannerImg.jpg'}
                                 className="img-fluid"
                                 alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding-bottom">
                <div className="section-padding-top section-padding-bottom section-bg container-x">

                    <div className="d-flex flex-md-row flex-column" style={{gap: 30}}>
                        <div style={{flex: 1}}>
                            <div className="d-flex align-items-start" style={{gap: 30}}>
                                <div>
                                    <img src={s3BaseURL + '/logo/corporated.jpg'}
                                         height={isMobileView ? 32 : 60}
                                         width={isMobileView ? 32 : 60}
                                         alt=""/>
                                </div>
                                <div>
                                    <div className="icon-title mb-2">June 2011</div>
                                    <div className="icon-text">When Incorporated</div>
                                </div>
                            </div>
                        </div>
                        <div style={{flex: 1}}>
                            <div className="d-flex align-items-start" style={{gap: 30}}>
                                <div>
                                    <img src={s3BaseURL + '/logo/assetsUnderManagment.jpg'}
                                         height={isMobileView ? 32 : 60}
                                         width={isMobileView ? 32 : 60}
                                    />
                                </div>
                                <div>
                                    <div className="icon-title mb-2">Rs 700 Crores</div>
                                    <div className="icon-text"> Assets Under Managment</div>
                                </div>
                            </div>
                        </div>
                        <div style={{flex: 1}}>
                            <div className="d-flex align-items-start " style={{gap: 30}}>
                                <div>
                                    <img
                                        src={s3BaseURL + '/logo/numberOfClientGlobal.jpg'}
                                        alt=""
                                        height={isMobileView ? 32 : 60}
                                        width={isMobileView ? 32 : 60}/>
                                </div>
                                <div>
                                    <div className="icon-title mb-2">1100</div>
                                    <div className="icon-text">Number of clients across the globe</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container section-padding-bottom">
                <div className="row">
                    <div className="col-lg-6 col-md-12 d-flex align-items-center justify-content-center">
                        <div>
                            <img src={s3BaseURL + '/about/aboutSectionBannerImg.jpg'}
                                 alt="" className="img-fluid"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div>
                            <div className="mb-5 d-flex align-items-start"
                                 style={{gap: 30}}>
                                <div>
                                    <img src={s3BaseURL + '/logo/ourMission.jpg'}
                                         height={isMobileView ? 32 : 60}
                                         width={isMobileView ? 32 : 60}
                                         className=""
                                         alt=""/>
                                </div>
                                <div>
                                    <div className="icon-title mb-3">Our Mission</div>
                                    <div className="icon-text">To do investments the way they ought to be done.</div>
                                </div>
                            </div>
                            <div className="mb-5  d-flex align-items-start"
                                 style={{gap: 30}}>
                                <div>
                                    <img src={s3BaseURL + '/logo/ourAproach.jpg'}
                                         height={isMobileView ? 32 : 60}
                                         width={isMobileView ? 32 : 60}
                                         className=" "
                                         alt=""/>
                                </div>
                                <div>
                                    <div className="icon-title mb-3">Our Approach</div>
                                    <div className="icon-text">We will work together to navigate in the future world of
                                        commerce, with complete honesty, integrity, transparency, and passion.
                                    </div>
                                </div>
                            </div>
                            <div className="mb-5  d-flex align-items-start"
                                 style={{gap: 30}}>
                                <div>
                                    <img src={s3BaseURL + '/logo/ourResponsibleInvesting.jpg'}
                                         className=""
                                         height={isMobileView ? 32 : 60}
                                         width={isMobileView ? 32 : 60}
                                         alt=""/>
                                </div>
                                <div>
                                    <div className="icon-title mb-3">Responsible Investing</div>
                                    <div className="icon-text">
                                        We consider important factors like the Environment, Social Responsibility
                                        and a company’s Governance standards before any investment recommendations
                                        are made for you.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="sectiondown">
                    <div className="col-md-12">
                        <h3 className="about-us-secondary-title text-center">
                            No Nonsense advice which is Strategic, Global and Simple.
                        </h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-xl-6 col-md-12">
                            <div className="d-flex align-items-start mb-5" style={{gap: 30}}>
                                <div>
                                    <img src={s3BaseURL + '/logo/strategy.jpg'}
                                         height={isMobileView ? 32 : 60}
                                         width={isMobileView ? 32 : 60}
                                         className="" alt=""/>
                                </div>
                                <div>
                                    <div className="icon-title mb-3">
                                        Strategic
                                    </div>
                                    <div className="icon-text">
                                        Investments are based on future trends. we identify sectors and companies that
                                        will lead the change
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-start mb-5" style={{gap: 30}}>
                                <div>
                                    <img src={s3BaseURL + '/logo/globalIcon.jpg'}
                                         height={isMobileView ? 32 : 60}
                                         width={isMobileView ? 32 : 60}
                                         className="" alt=""/>
                                </div>
                                <div>
                                    <div className="icon-title mb-3">
                                        Global
                                    </div>
                                    <div className="icon-text">
                                        21st Century economies are interconnected. Investments should have a global
                                        portfolio
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-start mb-5" style={{gap: 30}}>
                                <div>
                                    <img src={s3BaseURL + '/logo/simple.jpg'}
                                         height={isMobileView ? 32 : 60}
                                         width={isMobileView ? 32 : 60}
                                         className="" alt=""/>
                                </div>
                                <div>
                                    <div className="icon-title mb-3">
                                        Simple
                                    </div>
                                    <div className="icon-text">
                                        Too many investments in a portfolio is inefficient and limit growth
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-6 col-md-12">
                            <iframe className="video_plyar h-100"
                                    src='https://www.youtube.com/embed/8oy14KgGdvQ'
                                    style={{
                                        aspectRatio: isMobileView ? '16/9' : '',
                                        height: "100%", width: '100%'
                                    }}>
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section-padding-top section-padding-bottom">
                <div className="container">
                    <div id="Meet-Section"
                         className="section-title text-center">Meet Us</div>
                    <div className="section-description-text text-center py-4">Management Team</div>
                    {
                        activeProfile ? (
                            <div className="card p-3">
                                <i onClick={()=> {
                                    setActiveProfile(null);
                                }} className="fa-solid fa-xmark d-flex justify-content-end page-sub-title me-3 cursor"/>
                                <div className="row">
                                    <div className="col-lg-4 col-12 text-center">
                                        <img className='rounded-circle mb-4'
                                             style={{border: '1px solid #303030'}}
                                             width={"200px"}
                                             height={"200px"}
                                             src={activeProfile.imageUrl}
                                             alt="none"/>
                                        <div className='section-description-text font-bolder'>{activeProfile.fullName}</div>
                                        <div className='section-header font-normal mb-3'>{activeProfile.designation}</div>
                                    </div>
                                    <div className="col-lg-8 col-12 d-flex align-items-center">
                                        <div>
                                            <div className="content mb-3">
                                                {activeProfile.desc1}
                                            </div>
                                            <div className="content mb-3">
                                                {activeProfile.desc2}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className="d-flex justify-content-center flex-wrap text-center pb-5" style={{gap: 50}}>
                            {
                                meetUsObj.map((obj) => {
                                    return (
                                        <div  onClick={() => {
                                            setActiveProfile(obj) ;
                                            scrollToSection();
                                        }}>
                                            <img className='rounded-circle cursor'
                                                 style={{border: '1px solid #303030'}}
                                                 src={obj.imageUrl}
                                                 width={"200px"}
                                                 height={"200px"}
                                                 alt="none"/>
                                            <div className='content font-bolder cursor pt-4'>
                                                {obj.fullName}
                                            </div>
                                        </div>

                                    );
                                })
                            }
                        </div>)
                    }

                    <div  id="Additional-Director-Section"
                        className="section-description-text text-center py-4 mt-5">Independent Directors </div>
                    {
                        activeDirector ? (
                            <div className="card p-3">
                                <i onClick={()=> {
                                    setActiveDirector(null);
                                }} className="fa-solid fa-xmark d-flex justify-content-end page-sub-title me-3 cursor"/>
                                <div className="row">
                                    <div className="col-lg-4 col-12 text-center">
                                        <img className='rounded-circle mb-4'
                                             style={{border: '1px solid #303030'}}
                                             width={"200px"}
                                             height={"200px"}
                                             src={activeDirector.imageUrl}
                                             alt="none"/>
                                        <div className='section-description-text font-bolder'>{activeDirector.fullName}</div>
                                        <div className='section-header font-normal mb-3'>{activeDirector.designation}</div>
                                    </div>
                                    <div className="col-lg-8 col-12 d-flex align-items-center">
                                        <div>
                                            <div className="content mb-3">
                                                {activeDirector.desc1}
                                            </div>
                                            <div className="content mb-3">
                                                {activeDirector.desc2}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className="d-flex justify-content-center flex-wrap text-center " style={{gap: 50}}>
                            {
                                additionalDirectorObj.map((obj) => {
                                    return (
                                        <div  onClick={() => {
                                            setActiveDirector(obj) ;
                                            scrollToAddDirectorSection();
                                        }}>
                                            <img className='rounded-circle cursor'
                                                 style={{border: '1px solid #303030'}}
                                                 src={obj.imageUrl}
                                                 width={"200px"}
                                                 height={"200px"}
                                                 alt="none"/>
                                            <div className='content font-bolder cursor pt-4'>
                                                {obj.fullName}
                                            </div>
                                        </div>

                                    );
                                })
                            }
                        </div>)
                    }
                </div>
            </div>

            <div className="section-padding-top section-padding-bottom section-bg">
                <div className='section-title text-center pb-5'>
                    What Our Clients Are Saying
                </div>
                <div className="container">
                    <SelectYourProfileCarousal/>
                </div>
            </div>
            {renderFooter()}
        </div>


    )
        ;
}

AboutView.propTypes = {};

export default AboutView;
