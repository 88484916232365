import React from 'react';
import moment from "moment/moment";
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function UnionBudget(props) {
    const element=props.value;
    return (
        <div>
            {element.map((e,index)=>
                <div className="mb-5" key={index}>
                    <div className='page-title py-3'>{e.heading}</div>
                    <div className='date mb-3'>
                        <i className="fa-regular fa-calendar"/>
                        <span className="px-2">
                             {moment(e.created_at, "DD MMM YYYY, hh:mm A").format("MMM D, YYYY")}
                        </span>
                    </div>
                    <div className='img-sub-blog mb-3'>
                        <img className='img-fluid' src={s3BaseURL + e.image}/>
                    </div>
                    <div>
                        <div className='content mb-3'>{e.data.description}</div>
                        <div className='content mb-3'>{e.data.description1}</div>
                    </div>

                    <div className='img-sub-blog mb-3'>
                        <img className='img-fluid' src={s3BaseURL + e.data.image1}/>
                    </div>
                    <div>
                        <div className='content mb-3'>{e.data.description2}</div>
                        <div className='content mb-3'>{e.data.description3}</div>
                    </div>
                    <div className='img-sub-blog mb-3'>
                        <img className='img-fluid mb-3' src={s3BaseURL + e.data.image2}/>
                    </div>
                    <div>
                        <div className='content mb-3'>{e.data.description4}</div>
                        <div className='section-header'>{e.data.heading1}</div>
                        <div className='content mb-3'>{e.data.description5}</div>
                        <div className='content mb-3'>{e.data.description6}</div>
                        <div className='content mb-3'>{e.data.description7}</div>

                        <div className="section-header">{e.data.heading2}</div>
                        <div className='content mb-3'>{e.data.description8}</div>

                        <div className="section-header">{e.data.heading3}</div>
                        <div className='content mb-3'>{e.data.description9}</div>
                    </div>
                </div>
            )

            }
        </div>
    );
}

export default UnionBudget;
