import React, {useEffect, useState} from 'react';
import '../../assets/css/SIP.css';
import {Row} from "react-bootstrap";
import Footer from "../../core/layout/footer/footer";
import ExpectView from "./subSIPComponent/ExpectView";
import InvestView from "./subSIPComponent/InvestView";
import Disclaimer from "./subSIPComponent/Disclaimer";
import HomePageView from "../../home/HomePageView";

function SipView(props) {
    const {isMobileView, isXSView} = props;


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const [displayForm, setDisplayForm] = useState(false);


    const btnInvestFunction = () => {
        console.log('invest btn');
        setDisplayForm(true);
    };

    const btnExpectFunction = () => {
        console.log('expect btn');
        setDisplayForm(false);
    };

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };

    const renderUI = () => {
        if (isMobileView) {
            return (
                <div/>
            )
        }
        return (
            <div/>
        )
    };

    return (

        <div>
            <div className={isMobileView ? "" : "topsection"}/>
            {isMobileView ?
                <div className="container-x" >
                    <div className="d-flex align-items-center back-arrow">
                        <i className="fa-solid fa-arrow-left me-2" onClick={()=>{props.history.goBack()}}/>
                        <div className=" py-3" onClick={()=>{props.history.goBack()}}>Back</div>
                    </div>
                </div>
                :
                <div/>
            }

            <section className={isMobileView ? " pt-3 h-100" : ""}>
                <div className="container-x">
                    <div>
                        <div className="page-title mb-3 pt-lg-3 pt-0">SIP CALCULATOR</div>
                        <p className={isMobileView ? "d-none" : 'content'}>Calculate Returns & Investment for SIP's</p>
                    </div>
                    <div className="mb-3">
                        <div className="radio_btn">
                            <div className="form-check d-flex align-items-center">
                                <input className="form-check-input" type="radio"
                                       style={{backgroundImage: "none"}}
                                       name="flexRadioDefault"
                                       id="flexRadioDefault1" onClick={btnInvestFunction}/>
                                <label className="form-check-label pt-1">
                                    I want to Invest
                                </label>
                            </div>
                            <div className="form-check d-flex align-items-center">
                                <input className="form-check-input" type="radio"
                                       style={{backgroundImage: "none"}}
                                       name="flexRadioDefault" onClick={btnExpectFunction}
                                       id="flexRadioDefault2"
                                       checked={!displayForm}
                                />
                                <label className="form-check-label pt-1">
                                    I Expect
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={isMobileView ? "h-100 " : ''}>
                    {!displayForm &&
                    <ExpectView/>
                    }
                    {displayForm &&
                    <InvestView/>
                    }

                </div>

                <div className="modal fade"
                     id="exampleModal"
                     aria-labelledby="myLargeModalLabel"
                     aria-hidden="true">
                    <Disclaimer/>
                </div>
            </section>
            {isMobileView ? <div/> :
                <div>{renderFooter()}</div>
            }
        </div>
    );
}

SipView.propTypes = {};

export default SipView;
