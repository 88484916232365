export const aboutUS = {
    "status": true,
    "message": "Successfully got the data!",
    "data": {
        "id": 1,
        "heading": "About Us",
        "sub_heading": "Introduction",
        "description": "<p>Simplicity Investing India Private Limited is managed by an experienced and professional Team that is focused on providing Clients with an exceptional Investment experience. Our Team actively participates in the financial lives of our Clients and guidesthem on their investment decisions with thoughtful analysis,sound judgement, and relentless follow-through. We are committed to personalized service, objectivity, transparency and, above all, SIMPLICITY. We began with one simple goal&hellip; to partner with our Clients on a journey to Financial Freedom. We focus on the future,take a global view and minimize complexity. Our approach is comprehensive and holistic - and our key ingredients to a Client&rsquo;s Portfolio are Strategic. Global. Simple.</p>\r\n\r\n<p><em>Simple can be harder than complex: You have to work hard to get your thinking clean to make it simple. But it&rsquo;s worth it in the end because once you get there, you can move mountains.&nbsp;</em>&nbsp;<em>-Steve Jobs</em></p>",
        "writer_name": "Steve Jobs",
        "title_one": "Our Mission",
        "description_one": "To do investments the way they ought to be done.",
        "title_two": "Our Approach",
        "description_two": "We will work together to navigate in the future world of commerce, with complete honesty, integrity, transparency, and passion.",
        "title_three": "Responsible Investing",
        "description_three": "We consider important factors like the Environment, Social Responsibility and a company’s Governance standards before any investment recommendations are made for you.",
        "year_heading": "June 2011",
        "year_description": "When Incorporated",
        "asset_heading": "Rs 700 Crores",
        "asset_description": "Assets Under Management",
        "number_heading": "1611",
        "number_description": "Number of clients across the globe",
        "sense_heading": "No Nonsense advice which is Strategic, Global and Simple.",
        "sense_title_one": "Strategic",
        "sense_description_one": "Investments are based on future trends. we identify sectors and companies that will lead the change",
        "sense_title_two": "Global",
        "sense_description_two": "21st Century economies are interconnected. Investments should have a global portfolio",
        "sense_title_three": "Simple",
        "sense_description_three": "Too many investments in a portfolio is inefficient and limit growth",
        "video_url": "https://www.youtube.com/embed/8oy14KgGdvQ",
        "is_active": 1,
        "created_at": "2022-03-21T23:13:24.000000Z",
        "updated_at": "2024-01-30T11:58:25.000000Z",
        "media": [
            {
                "id": 10,
                "model_type": "App\\AboutUs",
                "model_id": 1,
                "uuid": "de0b246a-2aa6-4a2c-b243-c56796a2fe80",
                "collection_name": "aboutus_image",
                "name": "about2",
                "file_name": "1648194825.png",
                "mime_type": "image/png",
                "disk": "public",
                "conversions_disk": "public",
                "size": 88006,
                "manipulations": [],
                "custom_properties": [],
                "responsive_images": [],
                "description": null,
                "order_column": 8,
                "created_at": "2022-03-25T02:23:45.000000Z",
                "updated_at": "2022-03-25T02:23:45.000000Z"
            },
            {
                "id": 11,
                "model_type": "App\\AboutUs",
                "model_id": 1,
                "uuid": "2058560b-5bde-4c46-933d-f4a2939f7508",
                "collection_name": "year_icon",
                "name": "business-and-trade 1",
                "file_name": "1648194996.png",
                "mime_type": "image/png",
                "disk": "public",
                "conversions_disk": "public",
                "size": 1780,
                "manipulations": [],
                "custom_properties": [],
                "responsive_images": [],
                "description": null,
                "order_column": 9,
                "created_at": "2022-03-25T02:26:36.000000Z",
                "updated_at": "2022-03-25T02:26:36.000000Z"
            },
            {
                "id": 12,
                "model_type": "App\\AboutUs",
                "model_id": 1,
                "uuid": "9c88d28b-faf9-435d-aa1a-b302c308f170",
                "collection_name": "asset_icon",
                "name": "profit",
                "file_name": "1648194996.png",
                "mime_type": "image/png",
                "disk": "public",
                "conversions_disk": "public",
                "size": 2648,
                "manipulations": [],
                "custom_properties": [],
                "responsive_images": [],
                "description": null,
                "order_column": 10,
                "created_at": "2022-03-25T02:26:36.000000Z",
                "updated_at": "2022-03-25T02:26:36.000000Z"
            },
            {
                "id": 13,
                "model_type": "App\\AboutUs",
                "model_id": 1,
                "uuid": "33f660a4-76c6-4317-bcbb-b475c691b1d7",
                "collection_name": "number_icon",
                "name": "business-people",
                "file_name": "1648194996.png",
                "mime_type": "image/png",
                "disk": "public",
                "conversions_disk": "public",
                "size": 3188,
                "manipulations": [],
                "custom_properties": [],
                "responsive_images": [],
                "description": null,
                "order_column": 11,
                "created_at": "2022-03-25T02:26:36.000000Z",
                "updated_at": "2022-03-25T02:26:36.000000Z"
            },
            {
                "id": 14,
                "model_type": "App\\AboutUs",
                "model_id": 1,
                "uuid": "e82a45ae-fc7c-40dd-8fc2-46302b5bde26",
                "collection_name": "sense_icon_one",
                "name": "Strategic",
                "file_name": "1648195295.png",
                "mime_type": "image/png",
                "disk": "public",
                "conversions_disk": "public",
                "size": 3951,
                "manipulations": [],
                "custom_properties": [],
                "responsive_images": [],
                "description": null,
                "order_column": 12,
                "created_at": "2022-03-25T02:31:35.000000Z",
                "updated_at": "2022-03-25T02:31:35.000000Z"
            },
            {
                "id": 15,
                "model_type": "App\\AboutUs",
                "model_id": 1,
                "uuid": "f64c36d0-e898-48c0-8cac-35695516df6e",
                "collection_name": "sense_icon_two",
                "name": "Global",
                "file_name": "1648195295.png",
                "mime_type": "image/png",
                "disk": "public",
                "conversions_disk": "public",
                "size": 4061,
                "manipulations": [],
                "custom_properties": [],
                "responsive_images": [],
                "description": null,
                "order_column": 13,
                "created_at": "2022-03-25T02:31:35.000000Z",
                "updated_at": "2022-03-25T02:31:35.000000Z"
            },
            {
                "id": 16,
                "model_type": "App\\AboutUs",
                "model_id": 1,
                "uuid": "7c8dfe67-c875-42be-9569-cb55ed823d3b",
                "collection_name": "sense_icon_three",
                "name": "Simple",
                "file_name": "1648195295.png",
                "mime_type": "image/png",
                "disk": "public",
                "conversions_disk": "public",
                "size": 3692,
                "manipulations": [],
                "custom_properties": [],
                "responsive_images": [],
                "description": null,
                "order_column": 14,
                "created_at": "2022-03-25T02:31:35.000000Z",
                "updated_at": "2022-03-25T02:31:35.000000Z"
            },
            {
                "id": 107,
                "model_type": "App\\AboutUs",
                "model_id": 1,
                "uuid": "011be809-5799-41c3-a8e5-9d48e7ab4f7b",
                "collection_name": "icon_one",
                "name": "business 1",
                "file_name": "1648730034.png",
                "mime_type": "image/png",
                "disk": "public",
                "conversions_disk": "public",
                "size": 3897,
                "manipulations": [],
                "custom_properties": [],
                "responsive_images": [],
                "description": null,
                "order_column": 104,
                "created_at": "2022-03-31T07:03:54.000000Z",
                "updated_at": "2022-03-31T07:03:54.000000Z"
            },
            {
                "id": 108,
                "model_type": "App\\AboutUs",
                "model_id": 1,
                "uuid": "e7713077-3039-437c-b416-4a11a1d0ddb3",
                "collection_name": "icon_two",
                "name": "target 1",
                "file_name": "1648730034.png",
                "mime_type": "image/png",
                "disk": "public",
                "conversions_disk": "public",
                "size": 3901,
                "manipulations": [],
                "custom_properties": [],
                "responsive_images": [],
                "description": null,
                "order_column": 105,
                "created_at": "2022-03-31T07:03:54.000000Z",
                "updated_at": "2022-03-31T07:03:54.000000Z"
            },
            {
                "id": 110,
                "model_type": "App\\AboutUs",
                "model_id": 1,
                "uuid": "82753bd8-4a3e-48c2-bb2d-191b52ebce32",
                "collection_name": "icon_three",
                "name": "agreement 1",
                "file_name": "1648730152.png",
                "mime_type": "image/png",
                "disk": "public",
                "conversions_disk": "public",
                "size": 2962,
                "manipulations": [],
                "custom_properties": [],
                "responsive_images": [],
                "description": null,
                "order_column": 107,
                "created_at": "2022-03-31T07:05:52.000000Z",
                "updated_at": "2022-03-31T07:05:52.000000Z"
            },
            {
                "id": 111,
                "model_type": "App\\AboutUs",
                "model_id": 1,
                "uuid": "cd1ad4c9-3e8c-45e0-a42c-3dddff4b562a",
                "collection_name": "mission_image",
                "name": "IMG-20211210-WA0006 1",
                "file_name": "1648730152.png",
                "mime_type": "image/png",
                "disk": "public",
                "conversions_disk": "public",
                "size": 106373,
                "manipulations": [],
                "custom_properties": [],
                "responsive_images": [],
                "description": null,
                "order_column": 108,
                "created_at": "2022-03-31T07:05:52.000000Z",
                "updated_at": "2022-03-31T07:05:52.000000Z"
            }
        ]
    },
    "aboutus_image": "https://simplicityinvesting.com/backend/public//storage/10/1648194825.png",
    "year_icon": "https://simplicityinvesting.com/backend/public//storage/11/1648194996.png",
    "asset_icon": "https://simplicityinvesting.com/backend/public//storage/12/1648194996.png",
    "product_icon": "https://simplicityinvesting.com/backend/public//storage/13/1648194996.png",
    "mission_image": "https://simplicityinvesting.com/backend/public//storage/111/1648730152.png",
    "icon_one": "https://simplicityinvesting.com/backend/public//storage/107/1648730034.png",
    "icon_two": "https://simplicityinvesting.com/backend/public//storage/108/1648730034.png",
    "icon_three": "https://simplicityinvesting.com/backend/public//storage/110/1648730152.png",
    "sense_icon_one": "https://simplicityinvesting.com/backend/public//storage/14/1648195295.png",
    "sense_icon_two": "https://simplicityinvesting.com/backend/public//storage/15/1648195295.png",
    "sense_icon_three": "https://simplicityinvesting.com/backend/public//storage/16/1648195295.png"
};
