import React from 'react';
import SalariedView from "./SalariedView";
import {connect} from "react-redux";
import {Link, withRouter, NavLink} from "react-router-dom";

function Salaried(props) {
    return (
            <SalariedView {...props}/>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(Salaried));
