import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


function RiskManagementView(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    return (
        <div>
            <div className="topsection"/>
            <div className="main-container pt-lg-4 pt-0">
                <div className="container-x">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="page-title mb-3">
                                Risk Management
                            </div>
                            <div className="page-sub-title mb-3">
                                A customized investment service that addresses the needs of affluent clients
                            </div>
                            <div className="img-block px-5 d-lg-none d-block">
                                <img src={s3BaseURL + '/homepage/riskManagement.jpg'}
                                     className="img-fluid bannerImg mb-3"
                                     alt=""/>
                            </div>
                            <div className="content mb-3">In India, an affluent client is a person that has
                                financial
                                assets
                                of more than Rs. 2 crores.
                                The primary objective of Risk Management is to protect wealth and optimize
                                return.
                            </div>
                            <div className="content mb-3">Simplicity, with our expertise in global financial
                                markets,
                                works
                                with families to create a
                                vision, strategy and a process for managing the wealth.
                            </div>
                            <div className="content mb-3">
                                Our process in unique as we keep it simple, transparent, and
                                easy to
                                manage.
                            </div>
                            <div className="contact-us-text mb-3">Get in touch with us
                                    <a href="/contact-us" className="contact-us">Click Here</a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="img-block px-5 d-lg-block d-none">
                                <img src={s3BaseURL + '/homepage/riskManagement.jpg'}
                                     alt=""
                                     className="img-fluid bannerImg"/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {renderFooter()}
        </div>
    );
}

export default RiskManagementView;
