import React from 'react';
import BottomMenuOptionView from "./BottomMenuOptionView";
import {connect} from "react-redux";

import {withRouter} from "react-router-dom";

import {isEmpty} from "../../../utils/validations";


function BottomMenuOption(props) {

    const {account, isLoggedOutScreen, userAuthStatus} = props;

    return (
        <BottomMenuOptionView {...props}
                              // refreshWindowScrollElement={refreshWindowScrollElement}
        />
    );
}

BottomMenuOption.propTypes = {

};

const mapStateToProps = (state) => ({
    isXSView: state.appState.deviceInfo.isXSView,
    currentPathname: state.appState.currentPathname,
});

export default connect(mapStateToProps, {
    // resetEmailTemplateFilter,
    // setImportHistoryFilter,
    // changeDrawerStatus,
    // refreshSegmentsData,
    // refreshWindowScrollElement
})(withRouter(BottomMenuOption));
